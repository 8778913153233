
import AppHeader from '@/components/header/AppHeader.vue'
import ThreeTabsNav from '@/components/homepage/ThreeTabsNav.vue'
import TabsBox from '@/components/homepage/TabsBox.vue'
import CathegoryHeading from '@/components/homepage/CathegoryHeading.vue'
import NotificationsList from '@/components/homepage/NotificationsList.vue'

import { IonPage, IonContent } from '@ionic/vue'

import { alertCircleOutline } from 'ionicons/icons'

export default {
  components: {
    IonPage,
    IonContent,
    AppHeader,
    TabsBox,
    ThreeTabsNav,
    NotificationsList,
    CathegoryHeading,
  },
  setup() {
    return {
      alertCircleOutline,
    }
  },
}
