<template>
  <ion-grid>
    <ion-row v-for="(notification, index) in notifications" :key="index">
      <ion-item class="white-container list-item" lines="none">
        <notification-text
          :text="notification.text"
          :time="notification.date"
          :cathegory="notification.cathegory"
        />
      </ion-item>
    </ion-row>
  </ion-grid>
</template>

<script lang="ts">
import NotificationText from '@/components/homepage/NotificationText.vue'

import { IonGrid, IonRow } from '@ionic/vue'

export default {
  components: {
    IonGrid,
    IonRow,
    NotificationText,
  },
  data() {
    return {
      notifications: [
        {
          text: 'John Carpenter liked your PHOTO',
          date: '14.06.2021 9:08',
          cathegory: 'your posts',
        },
        { text: 'Make 100 FLICKS in one week', date: '14.06.2021 9:30', cathegory: 'challenges' },
        { text: 'Steve posted new VIDEO', date: '14.06.2021 9:56', cathegory: 'friends' },
      ],
    }
  },
}
</script>

<style scoped>
.white-container {
  margin: 7px 0;
  padding: 5px;
}
</style>
