
import NotificationText from '@/components/homepage/NotificationText.vue'

import { IonGrid, IonRow } from '@ionic/vue'

export default {
  components: {
    IonGrid,
    IonRow,
    NotificationText,
  },
  data() {
    return {
      notifications: [
        {
          text: 'John Carpenter liked your PHOTO',
          date: '14.06.2021 9:08',
          cathegory: 'your posts',
        },
        { text: 'Make 100 FLICKS in one week', date: '14.06.2021 9:30', cathegory: 'challenges' },
        { text: 'Steve posted new VIDEO', date: '14.06.2021 9:56', cathegory: 'friends' },
      ],
    }
  },
}
