<template>
  <ion-page>
    <app-header />
    <ion-content>
      <three-tabs-nav :alerts-active="true" />
      <tabs-box>
        <cathegory-heading
          :icon-name="alertCircleOutline"
          heading-text="notifications"
          link-text="delete all"
        />
        <notifications-list />
      </tabs-box>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import AppHeader from '@/components/header/AppHeader.vue'
import ThreeTabsNav from '@/components/homepage/ThreeTabsNav.vue'
import TabsBox from '@/components/homepage/TabsBox.vue'
import CathegoryHeading from '@/components/homepage/CathegoryHeading.vue'
import NotificationsList from '@/components/homepage/NotificationsList.vue'

import { IonPage, IonContent } from '@ionic/vue'

import { alertCircleOutline } from 'ionicons/icons'

export default {
  components: {
    IonPage,
    IonContent,
    AppHeader,
    TabsBox,
    ThreeTabsNav,
    NotificationsList,
    CathegoryHeading,
  },
  setup() {
    return {
      alertCircleOutline,
    }
  },
}
</script>
