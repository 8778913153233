<template>
  <div>
    <p>{{ text }}</p>
    <p class="small-text text-color-medium-shade">
      <span>{{ time }} / in </span>
      <span class="ion-text-uppercase text-color-secondary">{{ cathegory }}</span>
    </p>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    text: String,
    time: String,
    cathegory: String,
  },
}
</script>

<style scoped>
p:first-of-type {
  margin-bottom: 0;
}

p:nth-of-type(2) {
  margin: 6px 0;
}
</style>
